.home {
  display: flex;
  flex-direction: column;
}

.custom-line {
  border: none;
  height: 3px;
  background: linear-gradient(to right, #f1f7f1, #e4f3e4, #f1f7f1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin: 20px 0;
}
