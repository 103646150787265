.carousel .carousel-slider {
  margin: 0px auto;
  overflow: visible;
}

.carousel .slide img {
  margin: 30px;
  width: 400px;
  height: 400px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}
.carousel .slide iframe {
  margin: 30px;
  width: 35%;
}
.carousel .text-container p {
  padding: 10px;
  text-align: justify;
  margin-left: 5%;
  margin-right: 5%;
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
}

.carousel .text-container h2 {
  margin-top: 0px;
}

.carousel .text-container p:last-child {
  margin-bottom: 0px;
}

.carousel .control-dots {
  bottom: -40px;
}
.carousel .control-dots .dot {
  background-color: #d6f4d6;
  width: 15px;
  height: 15px;
}

@media screen and (max-width: 768px) {
  .carousel .slide img {
    margin: 30px;
    width: 250px;
    height: 250px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  }
}
