.social-media-panel {
  padding: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-icon {
  font-size: 50px;
  margin: 10px;
  color: #333;
}
