.contacts {
  max-width: 80%;
  margin: 0 auto;
  padding: 20px;
  display: flex;
}

.section-header {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
}

.contact-form {
  width: 60%;
  background-color: #f1f7f1;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  margin-right: 1%;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #555555;
}

input[type="text"],
input[type="email"],
textarea {
  width: 95%;
  padding: 10px;
  border: 1px solid #dddddd;
  border-radius: 4px;
  color: #333333;
  background-color: #f1f7f1;
}

textarea {
  height: 120px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

button:focus {
  outline: none;
}

.board-members {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 40%;
  background-color: #f1f7f1;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
}

.board-member {
  margin-bottom: 10px;
  width: calc(50% - 10px);
}

.board-member img {
  border-radius: 50%;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
}

.board-member .board-member-detail {
  text-align: center;
}

.board-member img {
  height: 200px;
  width: 200px;
}

.board-member h3 {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

.board-member p {
  margin: 0;
  font-size: 14px;
  color: #666666;
}

@media screen and (max-width: 960px) {
  .contacts {
    flex-direction: column;
  }
  .board-member img {
    height: 100px;
    width: 100px;
  }

  .board-members {
    width: 80%;
  }

  .contact-form {
    width: 80%;
  }
}
