.panel {
  width: 100%;
  margin: auto;
  padding: 10px;
  transition: background-color 0.3s ease;
  text-align: center;
  margin-top: 30px;
}

.panel .content {
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-size: 16px;
  color: #333;
  text-align: justify;
  line-height: 1.5;
  letter-spacing: 1px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 4px;
}

.panel .header {
  font-family: "Roboto", Arial, sans-serif;
  font-size: 24px;
  color: #333;
  text-transform: uppercase;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  margin-bottom: 10px;
}

.panel .video {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
}
