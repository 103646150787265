.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1000px;
  background-color: #f1f7f1;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  overflow: auto;
}

.modal-content {
  position: relative;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 20px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-header h2 {
  font-size: 24px;
  color: #333;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.modal-footer button {
  padding: 8px 16px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  color: #999;
  cursor: pointer;
}

.modal-body {
  display: flex;
  align-items: center;
}

.img-container {
  flex: 0 0 auto;
  margin-right: 20px;
}

.image-container img {
  max-width: 100%;
  height: auto;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
}

.details-container {
  flex: 1 1 auto;
  font-family: "Roboto", Arial, sans-serif;
  text-align: center;
  padding: 20px;
}

h2 {
  font-size: 24px;
  color: #333;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  display: flex;
  margin-bottom: 10px;
}

.label {
  text-align: right;
  font-weight: bold;
  color: #888;
  margin-right: 10px;
}

.value {
  text-align: left;
  color: #333;
}

@media screen and (max-width: 960px) {
  .img-container {
    display: none;
  }

  .modal {
    width: 300px;
    height: 300px;
  }
}
