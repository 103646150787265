.video-container iframe {
  height: 500px;
  width: 800px;
}

@media screen and (max-width: 768px) {
  .video-container iframe {
    height: 200px;
    width: 300px;
  }
}
