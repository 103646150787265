body {
  margin: 0;
  padding: 0;
  background-color: #f1f7f1;
}

body {
  font-family: Arial, sans-serif;
}

.logo {
  margin-right: 20px;
}

.logo img {
  max-width: 100%;
  height: auto;
}

main {
  padding: 40px 0;
}

.footer {
  background-color: #19191a;
  color: #fff;
  padding: 10px 0;
  text-align: center;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  display: none;
}

@media (min-height: 100vh) {
  .footer {
    display: block;
  }
}
