.products {
  margin-left: 20px;
  margin-right: 20px;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 60px;
}

.product {
  width: 300px;
  margin-bottom: 20px;
  padding: 10px;
  transition: background-color 0.3s ease;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #333;
  text-transform: uppercase;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 1px solid #ddd;
  border-radius: 8px;
}

.product img {
  width: 200px;
  height: 200px;
}

.product-header {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-transform: uppercase;
  position: relative;
}

.products-header:before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 2px;
  background-color: #333;
}

.product:hover {
  background-color: #ecf8ec;
}

.product h3 {
  margin: 5px;
}

.product p {
  margin-top: 10px;
}

.product a {
  display: block;
  margin-top: 10px;
}

@media screen and (max-width: 960px) {
  .product {
    width: 200px;
    margin-bottom: 10px;
    padding: 10px;
    transition: background-color 0.3s ease;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: #333;
    text-transform: uppercase;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  .product img {
    width: 100px;
    height: 100px;
  }
}
